  <template>
    <div class="wrap-body">
        <slot></slot>
        <template v-if="showService">
        </template>
      </div>
      <!-- <div class="wrap-body-right">
      </div> -->
</template>

<script>
export default {
  props: {
    showService: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
body{
     background-color: #333!important;
     padding: 25px 40px 25px 25px;
}
.wrap {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #C7C5F4, #776BCC);
  // background-image: url('../../../assets/img/logobg.jpg');
  background-repeat: round;
  background-size: 100%;
  display: flex;
  &-body {
    width: 440px;
    // height: 500px;
    margin: auto;
    padding: 60px 0;
    background-color: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    // display: flex;
    &-left {
      width: 50%;
      height: 100%;
      background-color: hsla(0, 0%, 98%, 0.95);
      // background-color: #fff;
      // background-image: url('../../../assets/img/loginBg.jpg');

    }
    &-right {
      width: 50%;
      height: 100%;
      position: relative;
      background-image: url('../../../assets/img/yhy.jpg');
      opacity: 0.9;
      // background-color: #ff4b2b;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top left;
      text-align: center;
      color: #fff;
      font-size: 22px;
      font-weight: bold;
      display: flex;
  align-items: center;
  justify-content: center;
      .service {
        position: absolute;
        border-right: 170px solid #f56c6c;
        border-bottom: 170px solid transparent;
        top: -15px;
        right: -15px;
      }
      .service-text {
        position: absolute;
        //width: 170px;
        letter-spacing:2px;
        font-size: 16px;
        top: 45px;
        right: 15px;
        color: #fff;
        transform: rotate(45deg);
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .wrap{
    width: 100%;
    min-width: 100%;
    .wrap-body{
      width: 100%;
      //background-color: #120f39;
      height: auto;
        .wrap-body-right{
          width: 20rem;
          height: 100%;
          margin: 2rem auto;
          border-radius: 0.3rem;
          .login-box{
            padding: 0.5rem 1rem;
          }
          .service{
            border-right: 5rem solid #f56c6c;
            border-bottom: 5rem solid transparent;
            top: 0px;
            right: 0px;
          }
          .service-text{
           font-size: 10px;
            top: 1.7rem;
            right: 0.16rem;
          }
          .login-tab {
            width: 100%;
              margin-top: 3rem;
            .tab{
              margin-right: 1.875rem;
              font-size: 0.625rem;
            }
          }
        }
    }
  }
}
</style>
